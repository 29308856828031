import React, { Fragment, useContext } from 'react';
import Avatar from 'react-avatar';
import Dropdown from 'react-bootstrap/Dropdown';
import AuthContext from '../../context/AuthContext';
import { logout } from '../../services/opw.api';
// import LogoutInactiveUser from '../LogoutInactiveUser/LogoutInactiveUser'

const UserAvatar = () => {
  const authContext = useContext(AuthContext);

  const handleLogOut = async () => {
    if (authContext) {
      try {
        await logout(authContext?.data?.token);
        authContext?.updateData('token', '');
        authContext?.updateData('user', null);
      } catch (err) {
        console.log('Logout error: ', err);
      }
    }
    window.location.href = '/';
  };

  const userFullName = () => {
    let name = '';
    if (authContext?.data?.user?.firstName) name = authContext?.data?.user?.firstName;
    if (authContext?.data?.user?.lastName) name += ' ' + authContext?.data?.user?.lastName;
    return name;
  };

  if (!authContext?.data?.user) return null;

  return (
    <Fragment>
      <Avatar name={userFullName()} size='38' round={true} color='#ff5722' />
      <Dropdown>
        <Dropdown.Toggle className='btn bg-white border-0 text-black'>
          <span className='d-none d-sm-inline-block'>{userFullName()}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item className='d-sm-none'>{userFullName()}</Dropdown.Item>
          <Dropdown.Item onClick={handleLogOut}>Log Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <LogoutInactiveUser onLogout={handleLogOut} timeout={180000} /> */}
    </Fragment>
  );
};

export default UserAvatar;
