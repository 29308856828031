// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-bar-link {
  margin-bottom: 5px;
}

.side-bar-link:last-child {
  margin-bottom: 0;
}

.side-bar-link:hover {
  background-color: #f8f9fa;
}

.side-bar-link.active {
  background-color: #e5faff;
  color: #009abe;
}

.launcher-card {
  aspect-ratio: 382/286;
}

.launcher-card-wide {
  aspect-ratio: 582/286;
}

.launcher-card-hover {
  position: relative;
  overflow: hidden;
}

.launcher-card-hover:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 80%;
  background-color: #013e62cc;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/LayoutStyles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,2BAA2B;AAC7B","sourcesContent":[".side-bar-link {\n  margin-bottom: 5px;\n}\n\n.side-bar-link:last-child {\n  margin-bottom: 0;\n}\n\n.side-bar-link:hover {\n  background-color: #f8f9fa;\n}\n\n.side-bar-link.active {\n  background-color: #e5faff;\n  color: #009abe;\n}\n\n.launcher-card {\n  aspect-ratio: 382/286;\n}\n\n.launcher-card-wide {\n  aspect-ratio: 582/286;\n}\n\n.launcher-card-hover {\n  position: relative;\n  overflow: hidden;\n}\n\n.launcher-card-hover:hover::after {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  opacity: 80%;\n  background-color: #013e62cc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
