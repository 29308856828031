import axios from 'axios';
import { APP_NAME } from '../helpers/constants';

const url = process.env.REACT_APP_OPW_SERVICE_API_ENDPOINT;

export const getToken = async (_email: string | null = null, _password: string | null = null) => {
  let email = process.env.REACT_APP_OPW_SERVICE_LOGIN_EMAIL;
  let password = process.env.REACT_APP_OPW_SERVICE_LOGIN_PASSWORD;
  if (_email) email = _email;
  if (_password) password = _password;

  const loginRequest = await axios.post(`${url}/auth/login`, {
    email,
    password,
  });

  if (loginRequest?.data?.access_token) {
    return loginRequest?.data?.access_token;
  } else {
    return null;
  }
};

export const getMe = async (token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const request = await axios.get(`${url}/users/me`, { headers });

  if (request?.data) {
    if (
      !request?.data?.data?.user?.userRoleList
        .map((obj: any) => obj.application.toLowerCase().trim())
        .includes(APP_NAME)
    ) {
      throw new Error('User role does not exist');
    }
    return request.data;
  } else {
    return null;
  }
};

export const microsoftLogin = async (token: string) => {
  const request = await axios.post(`${url}/auth/social/microsoft`, {
    token,
  });

  if (request?.data) {
    return request.data;
  } else {
    return null;
  }
};

export const logout = async (token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const request = await axios.get(`${url}/auth/logout`, { headers });

  if (request?.data) {
    return request.data;
  } else {
    return null;
  }
};
