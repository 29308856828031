import { Outlet } from 'react-router-dom';
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs';
import './LayoutStyles.css';
import MainHeader from './MainHeader';
import MainSideBar from './MainSideBar';

export const Layout = ({ withSidebar = true }) => {
  return (
    <div className='h-100 d-flex flex-column'>
      <MainHeader />
      <div className='flex-grow-1 d-flex overflow-hidden'>
        {withSidebar && (
          <div
            className='d-none d-lg-inline-block h-100 flex-shrink-1 overflow-hidden'
            style={{ minWidth: '310px' }}
          >
            <MainSideBar handleClose={null} />
          </div>
        )}

        <main
          className='flex-grow-1 h-100 py-4 px-2 px-sm-3 px-lg-5 bg-light'
          style={{ overflow: 'auto' }}
        >
          {withSidebar && <BreadCrumbs />}
          <Outlet />
        </main>
      </div>
    </div>
  );
};
