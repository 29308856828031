import { useState } from 'react';
// import MainLogo from '../../Assets/Images/'
import { Navbar, Offcanvas } from 'react-bootstrap';
import MainLogo from '../../assets/Images/ServiceAvengerLogo.png';
import MainSideBar from './MainSideBar';
import UserAvatar from './UserAvatar';

// interface Props {}

export default function MainHeader() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <header
      className='px-2 px-sm-3 px-lg-5 py-2 position-sticky top-0 w-100 bg-white'
      style={{ height: '53px', borderBottom: '1px solid #EEEEEE', zIndex: 99999 }}
    >
      <div className='d-flex justify-content-between h-100'>
        <div className='d-flex'>
          <Navbar collapseOnSelect expand='lg' className='p-0' bg='white' data-bs-theme='white'>
            <Navbar.Toggle onClick={handleShow} />
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <img
                    src={MainLogo}
                    alt='Logo'
                    height={37}
                    onClick={() => {
                      window.location.href = '/';
                    }}
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='overflow-hidden'>
                <MainSideBar handleClose={handleClose} />
              </Offcanvas.Body>
            </Offcanvas>
          </Navbar>
          <img
            src={MainLogo}
            alt='Logo'
            className='h-100'
            onClick={() => {
              window.location.href = '/';
            }}
          />
        </div>
        <div className='d-flex w-auto rounded-pill flex-sm-grow-0'>
          <UserAvatar />
        </div>
      </div>
    </header>
  );
}
