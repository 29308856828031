import React from 'react';
import { Row, Spinner } from 'react-bootstrap';

// interface Props {
//   show: boolean
// }
const FullScreenLoader = () => {
  return (
    <Row className='vh-100 v2-100 justify-content-center align-items-center'>
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </Row>
  );
};

export default FullScreenLoader;
