import React, { useContext } from 'react';
import Avatar from 'react-avatar';
import Dropdown from 'react-bootstrap/Dropdown';
import AuthContext from '../../context/AuthContext';
import LogoutInactiveUser from '../LogoutInactiveUser/LogoutInactiveUser';
import { handleLogOut } from '../../App';

const UserAvatar = () => {
  const authContext = useContext(AuthContext);
  const handleLogOutCustom = async () => {
    handleLogOut(authContext);
  };

  const userFullName = () => {
    let name = '';
    if (authContext?.data?.user?.firstName) name = authContext?.data?.user?.firstName;
    if (authContext?.data?.user?.lastName) name += ' ' + authContext?.data?.user?.lastName;
    return name;
  };

  if (!authContext?.data?.user) return null;

  return (
    <React.Fragment>
      <Avatar name={userFullName()} size='38' round={true} color='#ff5722' />
      <Dropdown>
        <Dropdown.Toggle className='btn bg-white border-0 text-black'>
          <span className='d-none d-sm-inline-block'>{userFullName()}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item className='d-sm-none'>{userFullName()}</Dropdown.Item>
          <Dropdown.Item onClick={handleLogOutCustom}>Log Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <LogoutInactiveUser onLogout={handleLogOutCustom} timeout={180000} /> */}
    </React.Fragment>
  );
};

export default UserAvatar;
