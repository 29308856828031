import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';

const BreadCrumbs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [data, setData] = useState<any[]>([]);

  const init = useCallback(() => {
    const paths = pathname.split('/');
    let newData = [];
    for (let i = 0; i < paths.length; i++) {
      if (paths[i] === '' && i === paths.length - 1) break;
      let href = paths.slice(0, i + 1).join('/');
      if (paths[i] === '') {
        newData.push({
          href: '/',
          label: 'Dashboard',
          active: paths[i + 1] === '',
        });
      } else if (paths[i] === 'claim') {
        newData.push({
          href: href,
          label: 'FlightPath',
          active: true || i === paths.length - 1,
        });
      } else if (paths[i] === 'warrantyplans') {
        newData.push({
          href: href,
          label: 'My Warranty Plans',
          active: true || i === paths.length - 1,
        });
      } else {
        newData.push({
          href: href,
          label: paths[i],
          active: true || i === paths.length - 1,
        });
      }
    }
    setData(newData);
  }, [setData, pathname]);

  useEffect(() => {
    init();
  }, [pathname, init]);

  if (data?.length === 1 && data[0].label === 'Dashboard') return null;

  return (
    <Breadcrumb>
      {data?.map((d) => (
        <Breadcrumb.Item
          onClick={() => navigate(d?.href)}
          active={d?.active}
          key={d?.label}
          style={{ cursor: 'pointer' }}
        >
          {d?.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
